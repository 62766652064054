@import "@mapix/common/src/assets/stylesheets/colors";

.filtersSearchAndSortWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 24px;

  .filtersAndSearchWrapper {
    display: flex;

    .searchInput {
      width: 310px;
      margin-right: 20px;
    }

    .filterIcon {
      margin-left: 6px;
      height: 18px;
      width: 18px;
      color: $primary60;
    }
  }

  .sortSelect {
    width: fit-content;

    .sortSelectInputContainer {
      border-color: $primary60;
    }
  }
}
