@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 300;
  }

  .cardContainer {
    margin-top: 24px;
  }
}

.inputContainer {
  margin-top: 24px;
  margin-bottom: 36px;

  > div {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;

      @include mobile {
        width: 100%;
      }
    }
  }

  &.storedCard {
    display: flex;
    justify-content: space-evenly;
  }
}

.addNewCardContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: auto;
  cursor: pointer;

  .plusContainer {
    padding: 8px 10px;
    background-color: $surface40;
    margin-left: 16px;
    border-radius: 8px;

    &Disabled {
      @extend .plusContainer;

      background-color: $surface20;
    }

    .plus {
      height: 16px;
      width: 16px;

      &Disabled {
        @extend .plus;

        filter: invert(96%) sepia(9%) saturate(90%) hue-rotate(156deg) brightness(86%) contrast(85%);
      }
    }
  }
}

.paymentAndReferenceWrapper {
  display: flex;
  justify-content: space-around;
  padding: 16px;
  background-color: $surface10;
  margin-bottom: 24px;
  border-radius: 16px;

  &Bill {
    @extend .paymentAndReferenceWrapper;

    flex-direction: column-reverse;
  }

  .pipe {
    border-left: 1px solid $secondary60;
    height: 20px;
  }

  .divider {
    border: 1px solid $secondary60;
    width: 100%;
  }
}
