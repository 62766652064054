@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  padding: 24px $create-side-padding 80px $create-side-padding;
  background-color: $surface20;
  min-height: $page-min-height;
  position: relative;
}

.input {
  max-width: 280px;
  margin-right: 20px;

  &Description {
    @extend .input;

    margin-top: 56px;
    max-width: 580px;
  }

  &Large {
    @extend .input;

    width: 320px;
  }
}

.checkbox {
  margin-right: 56px;
  text-transform: capitalize;
  align-items: center;

  .label {
    margin-left: 16px;
  }
}

.checkboxContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-content: center;

  > * {
    margin-bottom: 24px;
  }
}

.firstMargin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.area {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.sectionTitle {
  margin-top: 72px;
  margin-bottom: 15px;

  &Wrapper {
    @extend .sectionTitle;

    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      margin: 0 0 0 12px;

      .info {
        width: 24px;
        height: 24px;
        filter: invert(66%) sepia(61%) saturate(4111%) hue-rotate(196deg) brightness(100%) contrast(93%);
      }
    }
  }
}

.addressSubtitle {
  margin-top: 8px;
}

.form {
  display: flex;
  align-items: center;
}

.disclaimer {
  margin-top: 24px;
}

.modalContainer {
  width: 424px;
  text-align: center;
  line-height: 24px;

  .modalTitle {
    line-height: 32px;
    margin-bottom: 8px;
  }

  .modalButtonWrapper {
    display: flex;
    justify-content: center;

    .modalButton {
      padding: 8px 16px;
      width: 184px;
      height: 40px;
    }
  }
}

.semibold {
  font-weight: 600;
}

.fileUploadButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  label {
    color: $primary50;
    font-size: 16px;
  }
}

.fileErrorContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .bold {
    font-weight: bold;
  }

  .alertIcon {
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
}

.filesContainer {
  display: flex;
}
