@import "@mapix/common/src/assets/stylesheets/colors";

.modalContainer {
  background-color: $surface10;
  border-radius: 16px;
  padding: 24px;
  width: 424px;

  .titleAndCrossContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }

  .semiBold {
    font-weight: 600;
  }

  .crossIcon {
    width: 20px;
    height: 20px;
    filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
  }

  .price {
    font-weight: 600;
    margin-left: 8px;
  }

  .paidOn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 4px;
  }

  .taskAndCost {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }

  .totalAmount {
    @extend .semiBold;

    display: flex;
    align-items: center;
  }
}
