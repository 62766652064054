@import "@mapix/common/src/assets/stylesheets/colors";

.cardContainer {
  display: flex;
  flex-direction: row;
  background-color: $surface10;
  border-radius: 8px;
  padding: 14px 12px;

  .avatar {
    height: 48px;
    width: 48px;
    flex-shrink: 0;
  }

  .cardInfo {
    margin-left: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .nameAndWarningPill {
      display: flex;
      flex-direction: row;

      .name {
        margin-right: 16px;
      }

      .warningPill {
        margin-left: auto;
      }
    }

    .rejectedMessage {
      margin-top: 10px;
    }
  }

  .categoriesAndButton {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .categories {
      display: flex;
      align-items: center;

      .categoryIcon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }

    .button {
      height: 32px;
      width: 32px;
      background-color: $surface20;
      border-radius: 8px;
      right: 12px;
      bottom: 12px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .chevron {
        width: 16px;
        height: 16px;
        filter: invert(42%) sepia(12%) saturate(362%) hue-rotate(150deg) brightness(94%) contrast(80%);
      }
    }
  }
}
