@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";

$formLeftMargin: 16px;

.inputContainer {
  position: relative;
  width: 100%;

  /* Removes number arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield; /* Firefox */
  }
}

.select {
  display: flex;
  align-items: center;

  input {
    padding-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    caret-color: transparent;

    &:focus {
      outline: none;
    }
  }
}

.centerIcon {
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  left: 14px;
  color: $secondary80;
}

.regular {
  height: 40px;
  width: 100%;
  border: 1px solid $secondary60;
  border-radius: 8px;
  padding: 12px 8px;
  background-color: $surface10;

  &:active {
    background: $secondary20;
  }

  :focus {
    background: $secondary20;
  }

  ::placeholder {
    color: $textNeutral30;
  }

  &.withIcon {
    padding-left: 34px;
  }

  &:disabled {
    background-color: $surface50;
  }
}

.form {
  height: 48px;
  width: 100%;
  background-color: $surface10;
  border: none;
  border-bottom: 1px solid $primary30;
  border-radius: 8px 8px 0 0;
  padding: 8px $formLeftMargin;

  &:hover {
    border-bottom: 1px solid $primary50;
  }

  &:focus {
    border-bottom: 2px solid $primary50;
  }

  &:active {
    border: none;
    background: $secondary20;
    border-bottom: 1px solid $primary70;
  }

  &:disabled {
    background-color: $surface50;
  }

  &:placeholder-shown {
    border-bottom: 1px solid $primary30;
  }
}

.formError {
  @extend .form;

  border-bottom: 1px solid $danger50;

  &:active {
    background: $secondary20;
  }
}

.formLabel {
  position: absolute;
  top: 0;
  left: $formLeftMargin;
}

.helperLabel {
  position: absolute;
  margin-left: $formLeftMargin;
  margin-top: 4px;

  &.helperTextMaxLength {
    margin-bottom: -25px;
  }
}

.helperMargin {
  margin-top: 16px;
}

.chevron {
  pointer-events: none;
  position: absolute;
  color: $secondary90;

  &DropdownButton {
    @extend .chevron;

    color: $primary60;
  }
}

.chevronBlue {
  pointer-events: none;
  color: $primary50;
  position: absolute;
}

.chevronBox {
  display: flex;
  position: absolute;
  cursor: pointer;
  pointer-events: none;
  right: 18px;
  height: 24px;
  width: 24px;
}

.backgroundBlue {
  @extend .inputContainer;

  background-color: $primary10;
}

.blue {
  background-color: $primary10;
}

.dropdownButton {
  border-color: $primary60;
  padding: 8px 16px;
}

.formBlue {
  @extend .form;

  &:active {
    border: none;
    background: $secondary20;
  }

  border-bottom: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.dateIcon {
  display: flex;
  align-items: center;
  position: absolute;
  right: 14px;
}

.priceSymbol {
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: absolute;
}

.eyeIconContainer {
  position: absolute;
  right: 14px;
  filter: invert(38%) sepia(11%) saturate(258%) hue-rotate(168deg) brightness(94%) contrast(90%);

  .eyeIcon {
    width: 20px;
    height: 20px;
  }
}

.price {
  padding-left: 30px;
}

.rightIcon {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10px;
}
