@import "../../assets/stylesheets/colors";

.cancellationInfoCardContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  border-radius: 8px;
  padding: 14px 12px;

  .textDashed {
    text-decoration: line-through;
  }
}
