@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.messageContainer {
  display: flex;
  flex-direction: row;
  background-color: $surface40;
  padding: 8px;
  width: 100%;
  border-radius: 12px;
  align-items: center;

  .avatar {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }
}
