@import "@mapix/common/src/assets/stylesheets/colors";

.statusTabContainer {
  display: flex;
  flex-direction: column;

  .contractorAndCancellationContainer {
    display: flex;
    flex-direction: row;

    .contractorAndCancelledBy {
      display: flex;
      flex-direction: column;
      width: 45%;

      .cancelledByCard {
        margin-top: 20px;
      }
    }

    .cancellationInfoCard {
      margin-left: 40px;
      width: 45%;
    }
  }

  .divider {
    border: 1px solid $secondary40;
    width: 100%;
    margin: 24px 0;
  }

  .updates {
    padding-right: 15px;
    min-height: 50px;
    max-height: 290px;
    overflow: auto;
  }
}
