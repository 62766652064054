@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  min-height: $min-height;
  width: 100%;

  .subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.dateFilter {
  width: 230px;
  margin-left: auto;
  margin-right: 20px;
}

.tabContainer {
  margin-right: 20px;
}

.filterRow {
  margin-top: 24px;
  margin-bottom: 16px;
}

.input {
  width: 310px;
  margin-right: 20px;

  input::placeholder {
    font-size: 12px;
  }
}

.spinner {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.paginationContainer {
  align-self: end;
  margin-top: 24px;
  margin-right: 54px;
}

.icons {
  height: 24px;
  width: 24px;
  max-width: 24px;
  filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);
}

.getMoreIcon {
  margin-right: 24px;
  height: 24px;
  width: 24px;
  max-width: 24px;
  filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);
}

.emptyState {
  display: flex;
  width: 100%;
  margin-top: 160px;
}

.fileIcon {
  filter: invert(39%) sepia(82%) saturate(528%) hue-rotate(211deg) brightness(87%) contrast(91%);
}
