.checkboxContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 16px 8px;

  .checkboxWrapper {
    display: flex;
    align-items: center;
  }
}
