@import "@mapix/common/src/assets/stylesheets/colors";

.applicationCardWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px 20px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.confirmationModal {
  width: 35%;
}

.noApplicationsState {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .usersIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background-color: $primary20;
    border-radius: 100%;
    margin-bottom: 16px;

    .usersIcon {
      color: $primary70;
      width: 48px;
      height: 48px;
    }
  }
}
