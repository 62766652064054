@import "../../assets/stylesheets/colors";

.pin {
  width: 100%;
  height: 100%;
}

.purple {
  color: $primary60;
}

.yellow {
  color: $warning50;
}

.red {
  color: $danger40;
}

.leaflet-marker-icon {
  color: $warning50;
}
