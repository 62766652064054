@import "@mapix/common/src/assets/stylesheets/colors";

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  width: 750px;
  height: auto;
  padding: 32px;
  border-radius: 8px;

  .crossContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .crossIcon {
      width: 20px;
      height: 20px;
      filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
    }
  }

  .title {
    margin-bottom: 14px;
  }

  .filesContainer {
    .row {
      display: flex;
      align-items: center;
      background-color: unset;
    }
  }

  .fileErrorContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .bold {
      font-weight: bold;
    }

    .alertIcon {
      height: 24px;
      width: 24px;
      margin-left: 10px;
    }
  }

  .buttons {
    margin-top: 48px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    .button {
      height: 40px;
      margin-left: 20px;
      width: 184px;
    }

    .smallSpinner {
      display: flex;
      justify-content: center;
    }
  }
}
