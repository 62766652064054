@import "@mapix/common/src/assets/stylesheets/colors";

.modal {
  width: 80%;
  max-width: 860px;
  background-color: $secondary10;
  border-radius: 8px;
  padding: 40px;

  .titleAndCrossContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 36px;
  }

  .crossIcon {
    width: 20px;
    height: 20px;
    filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 60px;
  }
}
