@import "@mapix/common/src/assets/stylesheets/colors";

.modalContainer {
  display: flex;
  flex-direction: column;
  background-color: $surface10;
  width: 535px;
  height: auto;
  padding: 32px;
  border-radius: 8px;
}

.title {
  margin-bottom: 24px;
}

.buttons {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}

.button {
  height: 42px;
  width: 224px;
}

.spinnerContainer {
  width: 224px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  margin-top: 16px;
}
