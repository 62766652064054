@import "assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.modal {
  width: 600px;

  @include mobile {
    width: 352px;
  }
}
