@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 300;
  }
}

.inputContainer {
  margin-top: 24px;
  margin-bottom: 36px;

  > div {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;

      @include mobile {
        width: 100%;
      }
    }
  }

  &.storedCard {
    display: flex;
    justify-content: space-evenly;
  }
}

.titleContainer {
  display: flex;
  margin-bottom: 24px;

  .close {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: auto;
  }
}
