@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 40px;
  background-color: $surface20;
  min-height: $min-height;
  padding-bottom: 80px;

  @include mobile {
    padding: 0 16px;
  }
}

.menu {
  display: flex;
  margin-bottom: 24px;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 24px;

  @include mobile {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.input {
  width: 280px;

  &.description {
    width: 710px;

    @include mobile {
      width: 90%;
    }
  }

  &.large {
    width: 320px;
  }

  &.extralarge {
    width: 580px;
    margin-right: 20px;
  }
}

.checkboxContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  .checkbox {
    margin-right: 56px;
    text-transform: capitalize;
    align-items: center;

    @include mobile {
      margin-top: 24px;
    }
  }

  > * {
    margin-bottom: 24px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.searchColor {
  filter: invert(56%) sepia(77%) saturate(5538%) hue-rotate(232deg) brightness(97%) contrast(89%);
}

.dropdownContent {
  border: 1px solid $surface10;
  padding: 16px;
  color: $textBody;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 580px;
}

.listDropDown {
  margin-bottom: 16px;
}

.margin {
  margin-top: 72px;
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 24px;
    margin-top: 4px;
  }
}

.firstMargin {
  margin-top: 24px;
  margin-bottom: 24px;
}

.subtitleMobileMargin {
  margin-bottom: 24px;
}

.icons {
  height: 24px;
  width: 24px;
  max-width: 24px;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(38%) sepia(82%) saturate(595%) hue-rotate(214deg) brightness(87%) contrast(95%);
}

.contractorButton {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskButton {
  margin-left: 16px;
}

.area {
  display: flex;
  flex-direction: row;
  align-content: center;

  @include mobile {
    flex-direction: column;
  }
}

.iconCellLeftClass {
  min-width: 1%;
}

.footer {
  position: absolute;
  width: 100%;
  margin-top: 20px;

  @include mobile {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
}

.topContainer {
  display: flex;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.button {
  display: flex;
  justify-content: flex-end;
  width: 60%;
  height: 40px;
  margin-left: auto;

  @include mobile {
    display: none;
  }
}

.bold {
  font-weight: 600;
}

.info {
  @extend .bold;

  margin-top: 24px;
}

.inputMargin {
  @include mobile {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
