@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  width: 310px;
  border-radius: 16px;
  padding: 16px 16px 48px;
  background-color: $surface10;
  position: relative;

  .topSectionWrapper {
    width: 100%;
    display: flex;

    .avatarOrInitials {
      width: 88px;
      height: 88px;
      flex-shrink: 0;
      margin-right: 16px;
    }

    .basicInfoWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      .pill {
        padding: 3px 8px;
      }

      .plusContainerSpecializations {
        background-color: $primary10;
        color: $primary60;
      }

      .plusContainerCertifications {
        background-color: $tertiary10;
        color: $tertiary50;
      }
    }
  }

  .middleSectionWrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
    gap: 4px;
  }
}

.buttonsWrapper {
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  padding-bottom: 16px;
}
