@import "@mapix/common/src/assets/stylesheets/mixins";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  min-height: $min-height;
  width: 100%;

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.link {
  text-decoration: none;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 16px;

  @include mobile {
    display: none;
  }
}

.showingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  }
}

.showing {
  p {
    margin-top: 8px;
    margin-bottom: 30px;
  }

  @include mobile {
    font-weight: bold;
  }
}

.select {
  margin-left: 16px;

  @include mobile {
    margin-left: 0;
    margin-top: 8px;
  }
}

.filterIcon {
  margin-left: 6px;
  height: 18px;
  width: 18px;
  filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);

  @include mobile {
    margin-left: 0;
  }
}

.buttonsContainer {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  @include mobile {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.buttonsRight {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin-left: auto;
}

.buttonsLeft {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .propertyButton {
    margin-left: 8px;
  }
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
}

.claimButton {
  margin-left: 20px;
}

.icon {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-left: 32px;
  font-weight: bold;

  @include mobile {
    height: 20px;
    margin-left: 0;
    font-size: 12px;
  }
}

.iconNew {
  @extend .icon;

  width: 105px;
  background-color: $warning10;
  color: $warning50;

  @include mobile {
    width: 81px;
  }
}

.iconInProgress {
  @extend .icon;

  width: 126px;
  background-color: $info10;
  color: $info50;

  @include mobile {
    width: 99px;
  }
}

.iconSolved {
  @extend .icon;

  width: 109px;
  background-color: $success10;
  color: $success50;

  @include mobile {
    width: 73px;
  }
}

.iconRejected {
  @extend .icon;

  width: 109px;
  background-color: $danger10;
  color: $danger50;

  @include mobile {
    width: 84 px;
  }
}

.data {
  margin-top: 16px;
  width: 100%;

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    box-shadow: 0 2px 19px 1px $cardShadow;
    border-radius: 8px;
    padding: 0 40px;
    margin-bottom: 16px;

    .column {
      display: flex;
      flex-direction: column;

      p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }

  .cardMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 108px;
    box-shadow: 0 2px 19px 1px $cardShadow;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;

    .column {
      display: flex;
      flex-direction: column;

      p {
        margin-top: 2px;
        margin-bottom: 0;
      }
    }
  }
}

.created {
  font-weight: bold;
  margin-right: 8px;
}

.chevron {
  width: 40px;
  height: 40px;
  filter: invert(57%) sepia(9%) saturate(281%) hue-rotate(169deg) brightness(95%) contrast(82%);

  @include mobile {
    width: 24px;
    height: 24px;
  }
}

.paginationContainer {
  align-self: end;
  margin-top: 40px;
}

.bold {
  font-weight: bold;
}

.filter {
  width: 250px;

  @include mobile {
    width: 172px;
    margin-left: 8px;
    position: relative;
  }
}

.buttonFilter {
  @include mobile {
    width: 40px;
    height: 40px;
  }
}

.filterContainer {
  position: relative;

  .quantityFilters {
    position: absolute;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    bottom: 20px;
    background-color: $primary50;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $surface10;
    }
  }
}

.menu {
  margin-bottom: 24px;
}

.toolIcon {
  filter: invert(34%) sepia(9%) saturate(6330%) hue-rotate(221deg) brightness(95%) contrast(89%);
}

.emptyState {
  margin-top: 168px;

  @include mobile {
    margin-top: 80px;
  }
}

.spinner {
  margin-top: 200px;
  justify-content: center;
  display: flex;
}

.selectStatus {
  width: max-content;
}

.pill {
  margin-left: 32px;
  padding: 6px 24px;
  height: fit-content;
}
