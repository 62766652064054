@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.modalContainer {
  background-color: $surface10;
  border-radius: 8px;
  padding: 38px 24px 0;

  @include mobile {
    width: 90%;
    padding: 20px 16px 28px;
    box-shadow: 0 4px 8px $surface20;
    border: 1px solid $secondary50;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;

  @include mobile {
    margin: 0;
  }
}

.button {
  height: 40px;
  width: 184px;

  &.marginRight {
    margin-right: 8px;
  }

  @include mobile {
    width: 144px;
    margin-top: 20px;
  }
}

.containerIcon {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 16px;

  &Green {
    @extend .containerIcon;

    background-color: $tertiary10;
  }

  &Red {
    @extend .containerIcon;

    background-color: $danger05;
  }

  &Yellow {
    @extend .containerIcon;

    background-color: $warning10;
  }

  &Purple {
    @extend .containerIcon;

    background-color: $primary20;
  }
}

.icon {
  width: 56px;
  height: 56px;

  &Green {
    @extend .icon;

    filter: invert(58%) sepia(57%) saturate(390%) hue-rotate(115deg) brightness(97%) contrast(90%);
  }

  &Red {
    @extend .icon;

    filter: invert(23%) sepia(89%) saturate(1565%) hue-rotate(333deg) brightness(118%) contrast(90%);
  }

  &Yellow {
    @extend .icon;

    filter: invert(75%) sepia(18%) saturate(1559%) hue-rotate(334deg) brightness(92%) contrast(90%);
  }

  &Purple {
    @extend .icon;

    filter: invert(35%) sepia(99%) saturate(818%) hue-rotate(224deg) brightness(98%) contrast(87%);
  }
}

.title {
  margin-bottom: 12px;
  text-align: center;
  white-space: pre-line;
}

.content {
  width: 100%;
  text-align: center;
  white-space: pre-line;

  @include mobile {
    width: 100%;
  }
}

.count {
  display: flex;
  justify-content: flex-end;
}

.auxiliarIcon {
  position: absolute;
  right: 6.47%;
  bottom: 12.5%;
  width: 24px;
  height: 24px;
}

.crossContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.crossIcon {
  width: 20px;
  height: 20px;
  filter: invert(39%) sepia(1%) saturate(2471%) hue-rotate(179deg) brightness(95%) contrast(91%);

  @include mobile {
    margin-top: 0;
    margin-right: 0;
  }
}

p {
  margin: 0;
}

.oneButton {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;

  @include mobile {
    margin: 0;
  }
}

.iconWrapper {
  margin-top: 18px;
}
