@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

$vertical-padding: 32px;

// TODO this actually should be abstracted as a variable and considered for every container with bradcrumbs
$breadcrumbs-height: 20px;

.containerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $surface20;

  .container {
    position: relative;
    padding: $vertical-padding 40px;
    width: 100%;
    min-height: calc($min-height - 2 * $vertical-padding - $breadcrumbs-height);

    .titlePillWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .pill {
        margin-left: 56px;
        padding: 6px 24px;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 42px 22px;

      .divider {
        border: 1px solid $secondary40;
        width: 100%;
        margin-bottom: 40px;
      }

      .buttonsWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        .button {
          min-width: 264px;
          height: 48px;
          margin-left: 24px;
        }
      }
    }
  }
}
