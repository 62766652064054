@import "assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.tabsContainer {
  margin-top: 30px;
}

.movedToNewStatusModalContainer {
  width: 535px;
}

.movedToNewStatusButton {
  width: 224px;
}

.cancelTaskSuccessfulMaxWidth {
  max-width: 328px;
}

.inProgressRejectionModalContainer {
  width: 500px;

  .subtitle {
    margin-block: 10px;
  }

  .contractorMessageBox {
    margin-top: 20px;
  }
}
