@import "@mapix/common/src/assets/stylesheets/colors";

.divider {
  border: 1px solid $secondary40;
  width: 100%;
  margin: 24px 0;
}

.contractorCardWrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 16px;
  flex-direction: row;
  margin-top: 16px;

  .contractorCard {
    margin-right: 24px;
  }

  .button {
    margin-bottom: 16px;
  }

  .alert {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    color: $danger50;
  }
}

.contractorMessage {
  margin-bottom: 8px;
}

.lighterText {
  font-weight: 300;
}

.sentTo {
  margin: 16px 0 8px;
}

.whiteBoxContainer {
  margin-right: 32px;
}

.taskStatus {
  margin-bottom: 8px;
}

.updatesContainer {
  padding-right: 15px;
  min-height: 50px;
  max-height: 290px;
  overflow: auto;
}
