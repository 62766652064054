.contentContainer {
  text-align: left;

  .completionAndPaymentWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    grid-gap: 16px;
  }

  .messageWithTitle {
    height: 48px;
  }
}

.buttonContainer {
  width: 48%;
}
