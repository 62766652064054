@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/colors";

.wrapper {
  display: flex;
  position: relative;
}

.input.input {
  height: 48px;
  width: 100%;
  background-color: $surface10;
  border: none;
  border-bottom: 1px solid $primary30;
  border-radius: 8px 8px 0 0;
  padding: 8px 16px 8px 72px;
  font-size: 16px;

  &:hover {
    border-bottom: 1px solid $primary50;
  }

  &:focus {
    border-bottom: 2px solid $primary50;
  }

  &:active {
    border: none;
    background: $secondary20;
    border-bottom: 1px solid $primary70;
  }

  &:disabled {
    background-color: $surface50;
  }

  &:placeholder-shown {
    border-bottom: 1px solid $primary30;
  }
}

.button.button {
  margin-top: 4px;
  height: 40px;
  border: none;
  background-color: $surface10;
  top: 0;

  &Disabled {
    @extend .button;

    background-color: $surface50;
  }

  > div {
    width: 64px;

    > div > div {
      margin-left: 8px;
    }
  }
}

.content.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: $select-z-index;
  padding: 4px 8px;
  margin: 0;
  background-color: $surface10;
  max-height: 350px;
  overflow: auto;
  width: 100%;
  box-shadow: 0 2px 19px 1px $surface50;
  border-radius: 0 0 8px 8px;
  top: 46px;

  > li:empty {
    padding: 0;
  }

  > li[role="option"] {
    display: flex;
    text-align: left;
    padding: 10px 8px;
    border-bottom: 1px solid $secondary50;
    align-items: center;

    > div {
      display: flex;
      position: relative;
      left: 0;
      top: 0;
      margin-right: 8px;
    }

    &:hover {
      background-color: $secondary20;
    }

    &:active {
      background-color: $secondary30;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.container.container {
  border: none;
  width: 100%;
}

.formLabel {
  position: absolute;
  top: 0;
  left: 74px;
}

.helperLabel {
  position: absolute;
  margin-left: 16px;
  left: 0;
  bottom: 0;
  margin-bottom: -16px;
}
