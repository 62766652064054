@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/variables";

$min-height: calc(100vh - #{$header-height} - 36px);

.containerWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height: $min-height;
  background-color: $surface20;

  .container {
    position: relative;
    padding: 32px 40px;
    width: 100%;
    min-height: $min-height;

    .titlePillWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .pill {
        margin-left: 56px;
        padding: 6px 24px;
      }

      .warningPill {
        margin-left: 30px;
        padding: 6px 24px;
      }
    }

    .tabsWrapper {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
    }

    .actionsHeader {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      justify-content: space-between;
    }

    .footerPadding {
      padding-bottom: 100px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 42px 22px;

      .divider {
        border: 1px solid $secondary40;
        width: 100%;
        margin-bottom: 40px;
      }

      .buttonsWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        &FlexEnd {
          @extend .buttonsWrapper;

          justify-content: flex-end;
        }

        .button {
          min-width: 264px;
          height: 48px;
          margin-left: 24px;
        }
      }
    }
  }
}

.flexCenter {
  display: flex;
  align-items: center;
}
