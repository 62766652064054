@import "../../assets/stylesheets/variables";
@import "../../assets/stylesheets/mixins";

.modalContainer {
  position: fixed;
  z-index: $modal-z-index;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;

  &WithBackground {
    @extend .modalContainer;

    background-color: rgb(0 0 0 / 40%);

    @include mobile {
      background-color: rgb(217 217 217 / 50%);
    }
  }

  .content {
    margin: 5% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    &FixedTop {
      @extend .content;

      margin-top: 125px;
      height: fit-content;
    }
  }
}
