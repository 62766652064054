@use "sass:list";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap";

/*
  All generic variables go here. Please avoid
  adding code that generates CSS in this file.
*/

$primary-font-family: inter;
$primary-font: $primary-font-family, arial, sans-serif;
$select-z-index: 90;
$drawer-width: 60px;
$header-height: 48px;
$z-index-header: 8;
$z-index-error-message: 999;
$modal-z-index: 99;
$ios-padding-top: 44px;
$bottom-navigation-height: 48px;
$page-min-height: calc(100vh - #{$header-height});
$page-min-height-android: calc(100vh - #{$header-height} - #{$bottom-navigation-height});
$page-min-height-ios: calc(100vh - #{$header-height} - #{$bottom-navigation-height} - #{$ios-padding-top});
$z-index-location-select: 9;
$footer-height: 70px;
$min-height: 100vh;
$map-z-index: 5;
$drawer-z-index: 10;
$z-index-calendar: 5;
$z-index-tooltip: 10;

/* Breakpoint-related variables */
$breakpoints: (
  xl: 1400px,
  lg: 1200px,
  md: 992px,
  sm: 768px,
  xs: 414px,
);
$items-per-row: (
  xl: 5,
  lg: 4,
  md: 3,
  sm: 2,
  xs: 2,
  xxs: 1,
);
$pixeles: ();

@for $i from 1 through 100 {
  $pixeles: list.append($pixeles, $i);
}

$margin-top-sizes: $pixeles;
$margin-bottom-sizes: $pixeles;
$margin-right-sizes: $pixeles;
$margin-left-sizes: $pixeles;
$margin-block-sizes: $pixeles;
$margin-inline-sizes: $pixeles;
