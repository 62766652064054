.iconRow {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.hoverSpecialization {
  white-space: nowrap;
}

.plusIcon {
  border-radius: 50%;
}
