@import "../../assets/stylesheets/colors";

.buttonTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .iconButton {
    width: 54px;
    height: 40px;
    background: $surface20;
    border: 1px solid $primary60;
    border-radius: 8px;
    margin-bottom: 12px;

    .icon {
      filter: invert(37%) sepia(67%) saturate(3200%) hue-rotate(235deg) brightness(89%) contrast(84%);
    }
  }
}
