@import "@mapix/common/src/assets/stylesheets/colors";
@import "@mapix/common/src/assets/stylesheets/mixins";

.container {
  .titleAndSearchWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
    }

    .updates {
      font-weight: 300;
      margin-bottom: 8px;
    }

    .searchInput {
      margin-top: 8px;
      width: 344px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .message {
    margin-bottom: 40px;

    @include mobile {
      margin-bottom: 32px;
    }
  }

  .addUpdateButton,
  .solvedTaskMessage {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    align-items: center;

    .plusIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background-color: $surface10;
      margin-left: 16px;
      border-radius: 10px;

      .plusIcon {
        height: 16px;
        width: 16px;
        filter: invert(45%) sepia(5%) saturate(646%) hue-rotate(150deg) brightness(91%) contrast(95%);
      }
    }
  }
}
