@import "@mapix/common/src/assets/stylesheets/variables";
@import "@mapix/common/src/assets/stylesheets/colors";

.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 40px 16px;
  min-height: $min-height;
  width: 100%;
  background-color: $surface20;

  .subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.tabContainer {
  margin-right: 20px;
}

.filterRow {
  margin-top: 24px;
  margin-bottom: 16px;
}

.input {
  width: 310px;
  margin-right: 20px;

  input::placeholder {
    font-size: 12px;
  }
}

.filterIcon {
  margin-left: 6px;
  height: 18px;
  width: 18px;
  filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);
}

.propertyButton {
  margin-left: auto;
}

.plusIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
}

.spinner {
  margin-top: 200px;
  display: flex;
  justify-content: center;
}

.paginationContainer {
  align-self: end;
  margin-top: 24px;
  margin-right: 54px;
}

.icons {
  height: 24px;
  width: 24px;
  max-width: 24px;
  filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);

  &:first-child {
    margin-right: 24px;
  }
}

.archiveIconBlue {
  filter: invert(32%) sepia(100%) saturate(696%) hue-rotate(220deg) brightness(112%) contrast(85%);
  width: 56px;
  height: 56px;
}

.emptyState {
  display: flex;
  width: 100%;
  margin-top: 160px;
}

.iconDelete {
  width: 56px;
  height: 56px;
  filter: invert(42%) sepia(48%) saturate(2002%) hue-rotate(227deg) brightness(92%) contrast(100%);
}
