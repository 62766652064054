.tabsContainer {
  margin-top: 30px;
}

.inProgressRejectionModalContainer {
  width: 500px;

  .subtitle {
    margin-block: 10px;
  }

  .contractorMessageBox {
    margin-top: 20px;
  }
}
