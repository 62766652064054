@import "../../assets/stylesheets/colors";
@import "../../assets/stylesheets/mixins";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  word-break: break-all;

  &Reverse {
    @extend .container;

    flex-direction: row-reverse;

    .informationWrapper {
      width: 100%;
      margin-right: 8px;
    }
  }

  .informationWrapper {
    width: 100%;
    margin-left: 8px;
    max-width: 600px;

    .messageBubble {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      width: 100%;
      background-color: $surface10;
      border-radius: 8px;

      .seeMore {
        text-decoration: underline;
        margin-top: 12px;
        padding: 0;
      }

      .divider {
        width: 100%;
        border: 0;
        border-top: 1px solid $surface50;
      }

      .photosContainer {
        display: flex;
        flex-direction: row;
        margin-top: 3px;

        .photo {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          object-fit: cover;
        }
      }
    }

    .dateAndName {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
