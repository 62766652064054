@import "../../assets/stylesheets/colors";

.buttonContainer {
  /* Generic container attributes */
  box-shadow: 0 2px 4px $primary10;
  border: 1px solid $primary60;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 1s;

  &.primary {
    background: $primary50;
    color: $textNeutral10;
    box-shadow: 0 2px 4px $primary10;

    &:hover {
      background: $primary60;
      box-shadow: 0 2px 4px $primary20;
    }

    &:focus {
      background: $primary50;
      border: 2px solid $primary30;
      box-shadow: 0 2px 4px $primary10;
    }

    &:active {
      background: $primary70;
    }

    &:disabled {
      border: 1px solid $textNeutral20;
      color: $textNeutral20;
      background: none;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background: $surface20;
    color: $primary60;
    border-radius: 8px;
    box-shadow: 0 4px 8px $primary10;

    &:hover {
      background: $primary10;
      box-shadow: 0 4px 8px $primary20;
    }

    &:focus {
      border: 2px solid $primary50;
    }

    &:disabled {
      color: $textNeutral20;
      border: 1px solid $textNeutral20;
      background: $surface10;
      cursor: not-allowed;
    }
  }

  &.secondaryFilled {
    background: $secondary10;
    border: 1px solid $secondary20;
    box-shadow: 0 2px 4px $secondary70;

    &:hover {
      background: $secondary30;
      border-color: $secondary30;
      box-shadow: 0 4px 8px $secondary70;
    }

    &:focus {
      border: 2px solid $secondary30;
    }

    &:active {
      background-color: $secondary40;
      border-color: $secondary40;
    }

    &:disabled {
      color: $textNeutral20;
      border: 1px solid $textNeutral20;
      background: $surface10;
      cursor: not-allowed;
    }
  }

  &.secondaryOutlined {
    background: $secondary20;
    border: 1px solid $secondary80;
    box-shadow: 0 2px 4px $secondary10;

    &:hover {
      background: $secondary10;
      box-shadow: 0 4px 8px $secondary20;
    }

    &:focus {
      border: 2px solid $secondary40;
    }

    &:active {
      background-color: $secondary30;
      border-color: $secondary80;
    }

    &:disabled {
      color: $textNeutral20;
      border: 1px solid $textNeutral20;
      background: $surface10;
      cursor: not-allowed;
    }
  }

  &.secondaryBackgroundPrimary20 {
    @extend .secondary;

    background: $primary20;
  }

  &.ghostSecondary {
    padding: 8px 16px;
    border: none;

    &:hover {
      background-color: $secondary30;
    }

    &:focus {
      background-color: $secondary20;
      border: 2px solid $secondary40;
      border-radius: 8px;
    }

    &:active {
      background-color: $secondary40;
      border-radius: 8px;
    }

    &:disabled {
      color: $textNeutral20;
      cursor: not-allowed;
    }
  }
}

.link {
  color: $primary40;
  text-decoration: underline $primary40;
}

.primaryGradient {
  @extend .primaryGradient;

  background: linear-gradient(to right, $gradient50);
  color: $surface10;
}

.redButton {
  background: $surface20;
  color: $danger50;
  border-radius: 8px;
  box-shadow: 0 4px 8px $primary10;
  border-color: $danger50;

  &:hover {
    background: $primary10;
    box-shadow: 0 4px 8px $primary20;
  }
}

.closeIcon {
  margin-left: 8px;
  height: 24px;
  width: 24px;
  filter: invert(37%) sepia(14%) saturate(4934%) hue-rotate(223deg) brightness(96%) contrast(85%);
}

.closeButton {
  padding: 8px 16px;
}

.background {
  background: $primary20;
}
