@import "../../assets/stylesheets/colors";

.purple {
  color: $primary60;
}

.yellow {
  color: $warning50;
}

.red {
  color: $danger40;
}
