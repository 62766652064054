@import "@mapix/common/src/assets/stylesheets/colors";

.billsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    margin-bottom: 8px;
  }

  .subTitle {
    margin-bottom: 16px;
  }

  .tabContainer {
    margin-right: 20px;
  }

  .filterRow {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;

    .input {
      width: 310px;
      margin-right: 20px;

      input::placeholder {
        font-size: 12px;
      }
    }

    .filterIcon {
      margin-left: 6px;
      height: 18px;
      width: 18px;
      filter: invert(33%) sepia(95%) saturate(1164%) hue-rotate(230deg) brightness(89%) contrast(84%);
    }

    .plusIcon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      filter: invert(99%) sepia(9%) saturate(248%) hue-rotate(334deg) brightness(119%) contrast(100%);
    }
  }

  .emptyState {
    display: flex;
    width: 100%;
    margin-top: 160px;
  }

  .icons {
    height: 24px;
    width: 24px;
    max-width: 24px;
    filter: invert(36%) sepia(7%) saturate(430%) hue-rotate(169deg) brightness(98%) contrast(86%);

    &:first-child {
      margin-right: 24px;
    }
  }

  .registerPayment {
    border-color: $primary60;
  }

  .iconCellLeft {
    width: 100px;
  }

  .paymentButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 250px;
  }
}

.iconDelete {
  width: 56px;
  height: 56px;
  filter: invert(42%) sepia(48%) saturate(2002%) hue-rotate(227deg) brightness(92%) contrast(100%);
}

.dollarIcon {
  filter: invert(34%) sepia(9%) saturate(6330%) hue-rotate(221deg) brightness(95%) contrast(89%);
}

.paginationContainer {
  align-self: end;
  margin-top: 24px;
  margin-right: 54px;
}

.chevron {
  width: 24px;
  height: 24px;
  color: $secondary90;
}

.infoIcon {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.tooltip {
  text-wrap: wrap;
}

.spanClass {
  transform: translateX(-25%) !important;
}
