@import "@mapix/common/src/assets/stylesheets/colors";

.rateTabContainer {
  .margin {
    margin-top: 8px;
    padding: 0;
  }

  .taskHistory {
    @extend .margin;

    text-decoration: underline;
  }

  .ratePill {
    margin: 20px 0;
  }

  .message {
    max-width: 420px;
    margin-bottom: 20px;
  }

  .divider {
    border: 1px solid $secondary40;
    width: 100%;
  }

  .spaceBetween {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;

    .price {
      font-weight: 600;
      margin-left: 8px;

      &Dashed {
        @extend .price;

        text-decoration: line-through;
      }
    }

    .acceptModifyWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      .accept {
        margin-right: 26px;
      }
    }

    .modifyButton {
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding: 0;

      .editIcon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  .column {
    @extend .spaceBetween;

    flex-direction: column;
  }

  .payAmountPrice {
    color: $primary50;
  }
}
